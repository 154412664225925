<template>
  <v-navigation-drawer v-model="isVisible" class="grey lighten-4" fixed right temporary :width="drawerWidth">
    <template v-if="course && isTaxiCourse">
      <TaxiCourseDrawer
        :course="course"
        :selectedRide.sync="selectedRideIdx"
        style="width: 400px"
        @openSettlementColumn="openSettlementColumn"
        @close="closeDrawer"
      ></TaxiCourseDrawer>
      <v-divider class="mx-0" vertical></v-divider>
      <TaxiRideDrawer :course="course" :ride="selectedRide" style="width: 500px"></TaxiRideDrawer>
      <v-divider class="mx-0" vertical></v-divider>
      <SettlementDrawerFactory
        v-if="isSettlementColumnOpen"
        :ride="selectedRide"
        :course="course"
        style="width: 400px"
        @close="closeSettlementColumn"
      ></SettlementDrawerFactory>
    </template>
    <KilometerCourseDrawer
      v-else-if="course"
      class="kilometer-course__drawer"
      :course="course"
      :ride="ride"
      @close="closeDrawer"
    ></KilometerCourseDrawer>
  </v-navigation-drawer>
</template>
<script>
import { COURSE_TYPE_DICT } from '@/core/dict/course-dict';
import TaxiCourseDrawer from './course-drawer/taxi-course-drawer';
import TaxiRideDrawer from './ride-drawer/taxi-ride-drawer';
import SettlementDrawerFactory from './settlement-drawer/settlement-drawer-factory';
import KilometerCourseDrawer from './kilometer-course-drawer';

export default {
  name: 'CourseDrawer',
  components: {
    TaxiCourseDrawer,
    TaxiRideDrawer,
    SettlementDrawerFactory,
    KilometerCourseDrawer,
  },
  props: {
    course: Object,
    ride: Object,
  },
  data() {
    return {
      isVisible: false,
      selectedRideIdx: 0,
      isSettlementColumnOpen: false,
    };
  },
  computed: {
    drawerWidth() {
      if (this.isTaxiCourse) {
        return this.isSettlementColumnOpen ? 1300 : 900;
      }

      return 600;
    },
    selectedRide() {
      return this.course.rides[this.selectedRideIdx];
    },
    isTaxiCourse() {
      return this.course && this.course['@type'] === COURSE_TYPE_DICT.TAXI_COURSE;
    },
  },
  methods: {
    closeDrawer() {
      this.$emit('close');
      this.closeSettlementColumn();
    },
    resetCourseForm() {
      this.$store.commit('courseCreation/RESET_COURSE');
    },
    openSettlementColumn() {
      this.isSettlementColumnOpen = true;
    },
    closeSettlementColumn() {
      this.isSettlementColumnOpen = false;
    },
  },
  watch: {
    course: {
      handler(val) {
        this.isVisible = !!val;
      },
      immediate: true,
    },
    isVisible(val) {
      if (!val) {
        this.closeDrawer();
        this.resetCourseForm();
      }
    },
  },
};
</script>
<style scoped lang="scss">
// TODO: fix write to global scope
::v-deep.course-details .col-6 div:last-of-type {
  color: rgba(0, 0, 0, 0.54);

  .v-icon {
    color: rgba(0, 0, 0, 0.38);
  }
}

::v-deep .v-navigation-drawer__content {
  display: flex;
}

.kilometer-course__drawer {
  width: 100%;

  @media (min-width: 600px) {
    width: 600px;
  }
}
</style>
