// eslint-disable-next-line import/prefer-default-export
export class CreateCarForDriverStrategy {
  #store;

  constructor(store) {
    this.#store = store;
  }

  execute(payload) {
    return this.#store.dispatch('car/createCar', {
      carModel: payload,
      userModel: payload.user.user,
      company: this.#store.getters.user.company,
    });
  }
}
