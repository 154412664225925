import { TransferProtocolMapper } from './transfer-protocol.mapper';

// eslint-disable-next-line import/prefer-default-export
export class CarMapper {
  static toDTO({ carModel, userModel, company }) {
    return {
      brand: carModel.brand,
      model: carModel.model,
      engineCapacity: +carModel.engineCapacity,
      initialMileage: carModel.initialMileage,
      registrationNumber: carModel.registrationNumber,
      vin: carModel.vin,
      company: company ? company['@id'] : userModel.company['@id'],
      user: userModel['@id'],
      main: true,
    };
  }

  static toModel(carDTO) {
    return {
      // TODO: is temporary until I'll figure out why after login response doesn't include @id fields
      id: carDTO['@id'] ? carDTO['@id'].slice(1) : carDTO.id,
      brand: carDTO.brand,
      model: carDTO.model,
      mileageReadings: carDTO.mileageReadings,
      transferProtocols: carDTO.transferProtocols ? carDTO.transferProtocols.map(TransferProtocolMapper.toModel) : [],
      registrationNumber: carDTO.registrationNumber,
      vin: carDTO.vin,
      // TODO: think of maybe passing entire company object
      company: carDTO.company.name,
      fuelCards: carDTO.fuelCards,
      user: carDTO.user,
      engineCapacity: carDTO.engineCapacity,
      createdBy: carDTO.createdBy,
      createdAt: carDTO.createdAt,
      driverName: carDTO.driverName,
    };
  }
}
